import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Button, Avatar } from '@frontend/design-system';
import { SecureMessaging } from './secure-messaging';

interface VideoCallProps {
  providerName?: string;
  onEndCall?: () => void;
}

const containerStylesTelehealth = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.neutral90};
  display: flex;
  z-index: 3000;
  flex-direction: column;
`;

const mainVideoStyles = css`
  flex: 1;
  position: relative;
  background: ${theme.colors.neutral80};
  overflow: hidden;
`;

const participantVideoStyles = css`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 240px;
  height: 160px;
  border-radius: 12px;
  background: ${theme.colors.neutral70};
  border: 2px solid ${theme.colors.white};
  overflow: hidden;
`;

const controlBarStyles = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const topBarStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const connectionInfoStyles = css`
  position: absolute;
  top: 24px;
  left: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
`;

const waitingRoomStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const placeholderVideoStyles = css`
  width: 100%;
  height: 100%;
  background: ${theme.colors.neutral80};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const quickActionsStyles = css`
  position: absolute;
  right: 24px;
  top: 50%;
  padding: 8px;
  border-radius: 8px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${theme.colors.neutral90};
  border: 1px solid ${theme.colors.neutral70};
  svg {
    color: white;
  }
`;

type ConnectionIndicatorProps = {
  connectionQuality: 'good' | 'fair' | 'poor';
  isConnected: boolean;
};

const formatTime = (seconds: number) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

const ConnectionIndicator = ({ connectionQuality, isConnected }: ConnectionIndicatorProps) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if (isConnected) {
      const timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    return;
  }, [isConnected]);

  return (
    <div css={connectionInfoStyles}>
      <Icon
        name='wifi'
        size={16}
        color={connectionQuality === 'good' ? 'success' : connectionQuality === 'fair' ? 'warn' : 'error'}
      />
      <Text size='small' color='white'>
        {formatTime(elapsedTime)}
      </Text>
    </div>
  );
};

type QuickActionsProps = {
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
};

const QuickActions = ({ setShowChat }: QuickActionsProps) => (
  <motion.div css={quickActionsStyles}>
    <Button variant='secondary' iconName='message' onClick={() => setShowChat((prev) => !prev)} />
    <Button variant='secondary' iconName='document' />
  </motion.div>
);

const CameraComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    startCamera();
    return () => {
      stopCamera();
    };
  }, []);

  return <video ref={videoRef} autoPlay playsInline />;
};

const getConnectionQuality = (): 'good' | 'fair' | 'poor' => {
  const ratings = ['good', 'fair', 'poor'] as const;
  return ratings[Math.floor(Math.random() * ratings.length)];
};

export const VideoCall: React.FC<VideoCallProps> = ({ providerName = 'Dr. Charles Mingus', onEndCall }) => {
  const [isConnecting, setIsConnecting] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [connectionQuality] = useState<'good' | 'fair' | 'poor'>(() => getConnectionQuality());

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsConnecting(false);
      setIsConnected(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleEndCall = () => {
    onEndCall?.();
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} css={containerStylesTelehealth}>
      <motion.div css={mainVideoStyles}>
        {isConnecting ? (
          <div css={waitingRoomStyles}>
            <motion.div animate={{ scale: [1, 1.1, 1] }} transition={{ duration: 2, repeat: Infinity }}>
              <Avatar name={providerName} size='xxl' />
            </motion.div>
            <Text size='large' color='white' weight='semibold'>
              Connecting with {providerName}...
            </Text>
            <Text
              css={css`
                color: ${theme.colors.neutral5};
              `}
            >
              Please wait while we establish a secure connection
            </Text>
          </div>
        ) : (
          <>
            <div css={placeholderVideoStyles}>
              <Avatar name={providerName} size='xxl' />
            </div>
            <div css={participantVideoStyles}>
              <div css={placeholderVideoStyles}>
                {isVideoOff ? <Avatar name='You' size='large' isUser /> : <CameraComponent />}
              </div>
            </div>
            <ConnectionIndicator isConnected={isConnected} connectionQuality={connectionQuality} />
            <QuickActions setShowChat={setShowChat} />
          </>
        )}

        <div css={topBarStyles}>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 12px;
              margin-top: ${theme.spacing(1)};
            `}
          >
            <Icon name='lock' size={16} color='success' />
          </div>
        </div>

        <div css={controlBarStyles}>
          <Button
            variant='secondary'
            size='large'
            css={css`
              svg {
                color: white;
              }
            `}
            iconName={isMuted ? 'mute' : 'microphone'}
            onClick={() => setIsMuted(!isMuted)}
          />
          <Button
            variant='secondary'
            size='large'
            css={css`
              svg {
                color: white;
              }
            `}
            iconName={isVideoOff ? 'delete' : 'video'}
            onClick={() => setIsVideoOff(!isVideoOff)}
          />
          <Button destructive size='large' iconName='phone-hangup' onClick={handleEndCall} />
        </div>
      </motion.div>

      <AnimatePresence>
        {showChat && (
          <motion.div
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            exit={{ x: 400 }}
            transition={{ ease: 'linear' }}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: 400,
              zIndex: 3000,
            }}
            css={css`
              background: ${theme.colors.white};
              border-left: 1px solid ${theme.colors.neutral20};
            `}
          >
            <SecureMessaging isInVideo onBack={() => setShowChat(false)} />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {connectionQuality === 'poor' && (
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -100 }}
            css={css`
              position: absolute;
              bottom: 80px;

              padding: 8px 16px;
              background: ${theme.colors.neutral90};

              display: flex;
              justify-content: center;
              width: 100%;
              align-items: center;
              gap: 8px;
            `}
          >
            <Icon name='wifi' size={16} color='error' />
            <Text color='error'>Poor connection detected</Text>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
