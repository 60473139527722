import { FC, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { useIsAuthedShallowStore } from '../is-authed';

export const AuthenticatedRoute: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isAuthed: authenticated } = useIsAuthedShallowStore('isAuthed');
  const navigate = useNavigate();
  const signInUri = '/sign-in';

  useEffect(() => {
    if (!authenticated) {
      console.log(
        `Hit a private route without being authenticated. Redirecting to ${signInUri}, and setting the original URI to ${``}`
      );
      navigate({ to: signInUri });
    }
  }, [authenticated]);

  return authenticated ? children : null;
};
