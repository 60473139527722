import { motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { Button, Heading } from '@frontend/design-system';

export const Card = ({
  children,
  title,
  action,
  onAction,
  ...rest
}: {
  children: React.ReactNode;
  title: string;
  action: string;
  onAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <motion.div
    css={{
      '@media (max-width: 600px)': {
        padding: theme.spacing(2),
      },
      background: 'white',
      borderRadius: theme.borderRadius.medium,
      boxShadow: theme.shadows.light,
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
      overflow: 'auto',
    }}
    {...rest}
  >
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
      }}
    >
      <Heading level={3}>{title}</Heading>
      <Button variant='tertiary' onClick={onAction}>
        {action}
      </Button>
    </div>
    {children}
  </motion.div>
);
