import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Outlet, useNavigate } from '@tanstack/react-location';
import { theme } from '@frontend/theme';
import { Nav } from './components/nav';
import { PageHeader } from './components/page-header';

export const App = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate({ to: '/home', replace: true });
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
        height: 100dvh;
      `}
    >
      <PageHeader />
      <main
        id='main-content'
        css={{
          background: theme.colors.neutral5,
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          overflow: 'auto',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          css={css`
            max-width: 1600px;
            width: 100%;
          `}
        >
          <Outlet />
        </div>
      </main>
      <footer css={{ borderTop: `1px solid ${theme.colors.neutral20}` }}>
        <Nav />
      </footer>
    </div>
  );
};
