import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import type { Person } from '../person-types';

type IsAuthedStore = {
  person?: Person & { profilePicture?: string };
  setPerson: (person: Person) => void;
};

const mockPerson: Person = {
  Accounts: {},
  Address: {
    Street: '123 Tractor Lane',
    City: 'Moline',
    State: 'IL',
    Zip: '61265',
  },
  Department: '',
  Appointments: [],
  Birthdate: '1965-06-15',
  ClientLocationID: 'loc_789',
  ClientLocationName: 'Midwest Regional Office',
  ContactInfo: {
    EmailAddresses: ['john.deere@example.com'],
  },
  Email: 'john.deere@example.com',
  FirstName: 'John',
  Gender: 'Male',
  HomePhone: '309-555-1234',

  LastName: 'Deere',
  MobilePhone: '309-555-5678',
  Notes: 'Key account holder since 2010',
  OtherContactInfo: 'Available weekdays 9-5',
  PersonID: 'per_987654321',
  PMID: 'pm_123456',
  PMDisplayID: 'JD123',
  PreferredName: 'Johnny',
  Recall: [],
  ReceiveSMS: true,
  SourceID: 'src_456',
  SourceName: 'Website Signup',
  Status: 'Active',
  WeaveHouseholdID: 'hh_789012',
  WorkPhone: '309-555-9012',
  Preferences: {},
};

const personStore = createStoreWithPersistAndSubscribe<IsAuthedStore>(
  (set) => ({
    person: mockPerson,
    setPerson: (person) => set({ person }, false, 'SIGN_IN'),
  }),
  { name: 'tempPersonStore' },
  { name: 'tempPersonStore', trace: true }
);

export const usePersonStore = createShallowStore(personStore);
