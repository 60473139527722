import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

type IsAuthedStore = {
  isAuthed: boolean;
  signIn: () => void;
  signOut: () => void;
};

const useIsAuthedStore = createStoreWithPersistAndSubscribe<IsAuthedStore>(
  (set) => ({
    isAuthed: false,
    signIn: () =>
      set(
        (state) => {
          state.isAuthed = true;
        },
        false,
        'SIGN_IN'
      ),
    signOut: () =>
      set(
        (state) => {
          state.isAuthed = false;
        },
        false,
        'SIGN_OUT'
      ),
  }),
  { name: 'IsAuthedStore' },
  { name: 'IsAuthedStore', trace: true }
);

export const useIsAuthedShallowStore = createShallowStore(useIsAuthedStore);
