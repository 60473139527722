import { createBrowserHistory, ReactLocation } from '@tanstack/react-location';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AlertsProvider, ThemeProvider } from '@frontend/design-system';
import { Main } from './components/main';
import PWABadge from './PWABadge';

const history = createBrowserHistory();
const location = new ReactLocation({
  history,
});

export default function Root() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider includeEmotionTheme>
        <AlertsProvider>
          <Main location={location} />
          <PWABadge />
        </AlertsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
