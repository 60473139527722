// import { LoaderFn } from '@tanstack/react-location';
// import { PortalUser } from '@frontend/auth-helpers';
import {
  defaultMissingModuleFallback,
  defaultModuleFetchErrorFallback,
  getRoutesFromPaths,
  // WeaveLoaderData,
  RouteWithPath,
} from '@frontend/file-based-routing';
import { AuthenticatedRoute } from './components/authenticated-route';

export function getRoutes() {
  const paths = import.meta.glob<boolean, string, { [key: string]: any }>('/src/pages/**/[a-z[]*.tsx');
  return getRoutesFromPaths(paths, {
    AccessControl: AuthenticatedRoute,
    missingModuleFallback: defaultMissingModuleFallback,
    moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
  })
    .filter((route): route is RouteWithPath => !!route.path)
    .map((route) => {
      if (route.path.startsWith('/public')) {
        return { ...route, meta: { type: 'public' as const }, path: route.path.replace('/public', '') };
      } else {
        return { ...route, meta: { type: 'private' as const } };
      }
    })
    .map((route) => {
      return {
        ...route,
        // loader,
      };
    });
}

// const loader: LoaderFn<
//   WeaveLoaderData<{
//     LoaderData: { authenticated: boolean; user: PortalUser; lastLocationId: string };
//   }>
// > = async () => {
//   // const token = '123';
//   // const { isAuthed } = useIsAuthedShallowStore('isAuthed');
//   const authenticated = undefined;
//   // const user: PortalUser | undefined = token ? getUser() ?? (await authnClient.fetchUser()) : undefined;
//   const user: PortalUser | undefined = {
//     userID: '123',
//     firstName: '',
//     username: '',
//     type: 'weave',
//   };
//   // const lastLocationId = user ? getLoginData(user.userID)?.lastLocationIds?.[0] : undefined;

//   /**
//    * Data returned here will be available on each route, accessible via `useMatch`
//    * We could test for page access here as well (authenticated but not authorized)
//    */

//   return {
//     authenticated,
//     user,
//     // lastLocationId,
//   };
// };
