import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Button, Avatar } from '@frontend/design-system';
import { VideoCall } from './telehealth';

dayjs.extend(relativeTime);

interface Message {
  id: string;
  senderId: string;
  senderType: 'patient' | 'doctor' | 'system';
  senderName: string;
  content: string;
  timestamp: Date;
  status: 'sent' | 'delivered' | 'read';
  attachments?: {
    type: 'image' | 'document' | 'lab_result';
    name: string;
    size: string;
  }[];
  isUrgent?: boolean;
  isSystemMessage?: boolean;
  hasAppointmentRequest?: boolean;
}

const containerStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const headerStyles = css`
  padding: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.colors.neutral20};
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

const headerTopStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1.5)};
`;

const participantStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1.5)};
`;

const doctorStatusStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

const secureIndicatorStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(0.5)};
  padding: ${theme.spacing(0.5, 1)};
  background: ${theme.colors.success10};
  border-radius: ${theme.borderRadius.full};
  margin-top: ${theme.spacing(0.5)};
`;

const messageListStyles = css`
  flex: 1;
  overflow-y: auto;
  padding: ${theme.spacing(2)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  background: ${theme.colors.neutral5};
`;

const messageInputStyles = css`
  padding: ${theme.spacing(2)};
  border-top: 1px solid ${theme.colors.neutral20};
  background: ${theme.colors.white};
`;

const composerStyles = css`
  display: flex;
  gap: ${theme.spacing(1.5)};
  align-items: flex-start;
`;

const inputStyles = css`
  flex: 1;
  min-height: 80px;
  padding: ${theme.spacing(1.5)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  resize: none;
  font-size: ${theme.font.size.medium};
  line-height: ${theme.font.lineHeight};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary50};
    box-shadow: 0 0 0 2px ${theme.colors.primary20};
  }
`;

const appointmentRequestStyles = css`
  margin-top: ${theme.spacing(1.5)};
  padding: ${theme.spacing(1.5)};
  background: ${theme.colors.primary5};
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.primary20};
`;

const MessageBubble: React.FC<{ message: Message }> = ({ message }) => {
  const isOwnMessage = message.senderType === 'patient';
  const [showVideo, setShowVideo] = useState(false);

  if (message.isSystemMessage) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        css={css`
          display: flex;
          justify-content: center;
          margin: ${theme.spacing(1, 0)};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(0.5)};
            padding: ${theme.spacing(1, 1.5)};
            background: ${theme.colors.neutral10};
            border-radius: ${theme.borderRadius.medium};
            border: 1px solid ${theme.colors.neutral20};
          `}
        >
          <Icon name='lock' size={14} color='success' />
          <Text size='small' color='subdued'>
            {message.content}
          </Text>
        </div>
      </motion.div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: ${isOwnMessage ? 'flex-end' : 'flex-start'};
          gap: ${theme.spacing(0.5)};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(1)};
            ${isOwnMessage && 'flex-direction: row-reverse;'}
          `}
        >
          <Avatar size='small' name={message.senderName} isUser={isOwnMessage} />
          <Text size='small' color='subdued'>
            {message.senderName} • {formatChatTime(message.timestamp)}
          </Text>
        </div>

        <div
          css={css`
            max-width: 80%;
            padding: ${theme.spacing(2)};
            border-radius: ${theme.borderRadius.large};
            border: 1px solid ${isOwnMessage ? theme.colors.primary20 : theme.colors.neutral20};
            background: ${isOwnMessage ? theme.colors.primary10 : theme.colors.white};
            color: ${isOwnMessage ? theme.colors.white : theme.colors.neutral90};
          `}
        >
          <Text color={isOwnMessage ? 'default' : 'default'}>{message.content}</Text>

          {message.hasAppointmentRequest && (
            <div css={appointmentRequestStyles}>
              <Text weight='semibold'>Schedule Video Consultation</Text>
              <div
                css={css`
                  display: flex;
                  gap: ${theme.spacing(1)};
                  margin-top: ${theme.spacing(1)};
                `}
              >
                <Button size='small' variant='primary' iconName='video' onClick={() => setShowVideo(true)}>
                  Join Now
                </Button>
                <Button size='small' variant='secondary' iconName='calendar' onClick={() => {}}>
                  Schedule Later
                </Button>
              </div>
            </div>
          )}

          {message.attachments && (
            <motion.div
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              css={css`
                margin-top: ${theme.spacing(1)};
                padding: ${theme.spacing(1, 2, 1, 1)};
                background: ${isOwnMessage ? theme.colors.primary60 : theme.colors.neutral5};
                border-radius: ${theme.borderRadius.medium};
                display: flex;
                align-items: center;
                gap: ${theme.spacing(1)};
                cursor: pointer;
                transition: background 0.2s ease;

                &:hover {
                  background: ${isOwnMessage ? theme.colors.primary70 : theme.colors.neutral10};
                }
              `}
            >
              <div
                css={css`
                  padding: ${theme.spacing(1)};
                  border-radius: ${theme.borderRadius.medium};
                  border: 1px solid ${theme.colors.neutral20};
                `}
              >
                <Icon name='document-filled' size={16} color={isOwnMessage ? 'white' : 'primary'} />
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              >
                <Text size='small' color={isOwnMessage ? 'white' : 'default'}>
                  {message.attachments[0].name}
                </Text>
                <Text size='small' color={isOwnMessage ? 'white' : 'subdued'}>
                  {message.attachments[0].size}
                </Text>
              </div>
              <Icon name='download' size={16} color={isOwnMessage ? 'white' : 'primary'} />
            </motion.div>
          )}
        </div>

        {message.status && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <Icon
              name={message.status === 'read' ? 'check' : 'check'}
              size={12}
              color={message.status === 'read' ? 'success' : 'subdued'}
            />
            <Text size='small' color='subdued'>
              {message.status === 'read' ? 'Read' : 'Delivered'}
            </Text>
          </div>
        )}
      </motion.div>
      <AnimatePresence>{showVideo && <VideoCall onEndCall={() => setShowVideo(false)} />}</AnimatePresence>
    </>
  );
};

const formatChatTime = (timestamp: number | Date): string => {
  const date = dayjs(timestamp);
  const now = dayjs();
  const diffHours = now.diff(date, 'hour');

  if (diffHours < 24) {
    return date.fromNow();
  }

  if (date.year() === now.year()) {
    return date.format('MMM D, h:mm A');
  }

  return date.format('MMM D YYYY, h:mm A');
};

interface Provider {
  id: string;
  name: string;
  practice: string;
  avatarUrl?: string;
}

export const SecureMessaging = ({
  onBack,
  isInVideo,
  provider,
}: {
  onBack: () => void;
  isInVideo?: boolean;
  provider?: Provider | null;
}) => {
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '0',
      senderId: 'system',
      senderType: 'system',
      senderName: 'System',
      content: 'This is a secure, HIPAA-compliant messaging session',
      timestamp: new Date('2024-10-24T09:55:00'),
      status: 'delivered',
      isSystemMessage: true,
    },
    {
      id: '1',
      senderId: 'doctor1',
      senderType: 'doctor',
      senderName: provider?.name ?? 'Dr. Charles Mingus',
      content:
        "Hello! I've reviewed your latest lab results. Your cholesterol levels have improved, but I'd like to discuss some adjustments to your treatment plan.",
      timestamp: new Date('2024-10-24T10:00:00'),
      status: 'read',
      attachments: [{ type: 'lab_result', name: 'Lipid Panel Results.pdf', size: '1.2 MB' }],
    },
    {
      id: '2',
      senderId: 'patient1',
      senderType: 'patient',
      senderName: 'You',
      content: 'Thank you for checking. Is there anything I should be concerned about?',
      timestamp: new Date('2024-10-24T10:05:00'),
      status: 'read',
    },
    {
      id: '3',
      senderId: 'doctor1',
      senderType: 'doctor',
      senderName: provider?.name ?? 'Dr. Charles Mingus',
      content:
        'Nothing to be concerned about - your HDL levels are improving nicely. Would you like to schedule a video consultation to discuss the medication adjustments?',
      timestamp: new Date('2024-10-24T10:10:00'),
      status: 'delivered',
      hasAppointmentRequest: true,
    },
  ]);

  const [isTyping, setIsTyping] = useState(false);
  const [showEncrypted, setShowEncrypted] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showVideo, setShowVideo] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div css={containerStyles}>
        <div css={headerStyles}>
          <div css={headerTopStyles}>
            <div css={participantStyles}>
              <Button
                onClick={() => onBack()}
                css={css`
                  margin-right: ${theme.spacing(-0.5)};
                  margin-left: ${theme.spacing(-1)};
                `}
                iconName='caret-left'
                variant='secondary'
              />

              <div
                css={css`
                  position: relative;
                `}
              >
                <Avatar name={provider?.name ?? 'Dr. Charles Mingus'} size='medium' />
                <div
                  css={css`
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 12px;
                    height: 12px;
                    background: ${theme.colors.success50};
                    border-radius: 50%;
                    border: 2px solid ${theme.colors.white};
                  `}
                />
              </div>
              <div>
                <Text weight='semibold'>{provider?.name ?? 'Dr. Charles Mingus'}</Text>
                {!isInVideo && (
                  <div css={doctorStatusStyles}>
                    <Text size='small' color='subdued'>
                      Cardiologist • Online
                    </Text>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 2px;
                      `}
                    >
                      <Icon name='shield' size={14} color='success' />
                      <Text size='small' color='success'>
                        Verified Provider
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!isInVideo && (
              <div
                css={css`
                  display: flex;
                  gap: ${theme.spacing(1)};
                `}
              >
                <Button onClick={() => setShowVideo(true)} iconName='video' variant='secondary' size='small' />
                <Button iconName='phone' variant='secondary' size='small' />
                <Button iconName='more' variant='secondary' size='small' />
              </div>
            )}
          </div>
          {showEncrypted && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              css={secureIndicatorStyles}
            >
              <Icon name='lock' size={14} color='success' />
              <Text size='small' color='success'>
                Message encrypted and secure
              </Text>
            </motion.div>
          )}
        </div>

        <div css={messageListStyles}>
          <AnimatePresence>
            {messages.map((message) => (
              <MessageBubble key={message.id} message={message} />
            ))}
          </AnimatePresence>

          {isTyping && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              css={css`
                display: flex;
                align-items: center;
                gap: ${theme.spacing(1)};
                padding: ${theme.spacing(1)};
              `}
            >
              <Avatar name={provider?.name ?? 'Dr. Charles Mingus'} size='small' />
              <motion.div
                animate={{ opacity: [0.4, 1, 0.4] }}
                transition={{ duration: 1.5, repeat: Infinity }}
                css={css`
                  padding: ${theme.spacing(1, 2)};
                  background: ${theme.colors.white};
                  border-radius: ${theme.borderRadius.large};
                  display: flex;
                  gap: ${theme.spacing(0.5)};
                `}
              >
                <motion.span animate={{ y: [0, -5, 0] }} transition={{ duration: 0.5, delay: 0, repeat: Infinity }}>
                  •
                </motion.span>
                <motion.span animate={{ y: [0, -5, 0] }} transition={{ duration: 0.5, delay: 0.2, repeat: Infinity }}>
                  •
                </motion.span>
                <motion.span animate={{ y: [0, -5, 0] }} transition={{ duration: 0.5, delay: 0.4, repeat: Infinity }}>
                  •
                </motion.span>
              </motion.div>
            </motion.div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <MessageComposer setMessages={setMessages} setIsTyping={setIsTyping} setShowEncrypted={setShowEncrypted} />
      </div>
      <AnimatePresence>{showVideo && <VideoCall onEndCall={() => setShowVideo(false)} />}</AnimatePresence>
    </>
  );
};

type MessageComposerProps = {
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  setIsTyping: (isTyping: boolean) => void;
  setShowEncrypted: (showEncrypted: boolean) => void;
};

const MessageComposer = ({ setMessages, setIsTyping, setShowEncrypted }: MessageComposerProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [newMessage, setNewMessage] = useState('');

  const handleSend = () => {
    if (!newMessage.trim()) return;

    const message: Message = {
      id: Date.now().toString(),
      senderId: 'patient1',
      senderType: 'patient',
      senderName: 'You',
      content: newMessage.trim(),
      timestamp: new Date(),
      status: 'sent',
    };

    setMessages((prev) => [...prev, message]);
    setNewMessage('');
    setShowEncrypted(true);
    setTimeout(() => setShowEncrypted(false), 2000);

    setIsTyping(true);
    setTimeout(() => {
      const response: Message = {
        id: (Date.now() + 1).toString(),
        senderId: 'doctor1',
        senderType: 'doctor',
        senderName: 'Dr. Charles Mingus',
        content:
          "I can do a video consultation now if you're available. Otherwise, we can schedule one for later this week.",
        timestamp: new Date(),
        status: 'sent',
        hasAppointmentRequest: true,
      };
      setMessages((prev) => [...prev, response]);
      setIsTyping(false);
    }, 3000);
  };

  return (
    <div css={messageInputStyles}>
      <div css={composerStyles}>
        <textarea
          ref={textAreaRef}
          css={inputStyles}
          placeholder='Type your message...'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <div
          css={css`
            display: flex;
            gap: ${theme.spacing(1)};
          `}
        >
          <Button iconName='attachment' variant='secondary' />
          <Button variant='primary' onClick={handleSend} disabled={!newMessage.trim()}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};
