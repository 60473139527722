/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Avatar, Button, NakedButton, Heading, Chip } from '@frontend/design-system';
import { Card } from './card';

const addTimeToDate = (date: Dayjs, timeString: string) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  const military = timeString.match(/^(\d{1,2}):(\d{2})(?::(\d{2}))?$/);

  const standard = timeString.match(/^(\d{1,2}):(\d{2})\s*(AM|PM)$/i);

  if (military) {
    hours = parseInt(military[1]);
    minutes = parseInt(military[2]);
    seconds = military[3] ? parseInt(military[3]) : 0;

    if (hours >= 24 || minutes >= 60 || seconds >= 60) {
      throw new Error('Invalid time values');
    }
  } else if (standard) {
    hours = parseInt(standard[1]);
    minutes = parseInt(standard[2]);
    const meridiem = standard[3].toUpperCase();

    if (hours > 12 || minutes >= 60) {
      throw new Error('Invalid time values');
    }

    if (meridiem === 'PM' && hours !== 12) {
      hours += 12;
    } else if (meridiem === 'AM' && hours === 12) {
      hours = 0;
    }
  } else {
    throw new Error('Invalid time format. Use "HH:mm", "HH:mm:ss", or "h:mm AM/PM"');
  }

  const result = dayjs(date).hour(hours).minute(minutes).second(seconds);

  return result;
};

export interface Appointment {
  id: string;
  providerId: string;
  providerName: string;
  providerPractice: string;
  date: string;
  startTime: string;
  endTime: string;
  reason: string;
  visitType: string;
  type: 'telehealth' | 'in-person';
  status: 'upcoming' | 'completed' | 'cancelled';
}

interface AppointmentStore {
  appointments: Appointment[];
  addAppointment: (appointment: Appointment) => void;
  cancelAppointment: (id: string) => void;
  getUpcomingAppointments: () => Appointment[];
}

export const useAppointmentStore = create<AppointmentStore>()(
  persist(
    (set, get) => ({
      appointments: [],
      addAppointment: (appointment) =>
        set((state) => ({
          appointments: [...state.appointments, appointment],
        })),
      cancelAppointment: (id) =>
        set((state) => ({
          appointments: state.appointments.map((apt) => (apt.id === id ? { ...apt, status: 'cancelled' } : apt)),
        })),
      getUpcomingAppointments: () => {
        const now = dayjs();
        return get()
          .appointments.filter((apt) => {
            const aptDate = addTimeToDate(dayjs(apt.date), apt.startTime);
            return aptDate.isAfter(now) && apt.status === 'upcoming';
          })
          .sort((a, b) => {
            const dateA = addTimeToDate(dayjs(a.date), a.startTime);
            const dateB = addTimeToDate(dayjs(b.date), b.startTime);
            return dateA.diff(dateB);
          });
      },
    }),
    {
      name: 'appointments-storage',
    }
  )
);

const appointmentListStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const appointmentCardStyles = (isTelemedicine: boolean) => css`
  padding: 16px;
  border-radius: 12px;
  background: ${theme.colors.white};
  border: 1px solid ${isTelemedicine ? theme.colors.primary20 : theme.colors.neutral20};
  position: relative;
  overflow: hidden;
`;

const appointmentHeaderStyles = css`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const appointmentInfoStyles = css`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const MotionNakedButton = motion(NakedButton);

const AppointmentCard: React.FC<{ appointment: Appointment }> = ({ appointment }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isTelemedicine = appointment.type === 'telehealth';
  const formattedDate = new Date(appointment.date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <>
      <MotionNakedButton
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        css={[
          appointmentCardStyles(isTelemedicine),
          css`
            width: 100%;

            :hover {
              background: ${theme.colors.neutral5};
            }
          `,
        ]}
        onClick={() => setIsModalOpen(true)}
      >
        <div css={appointmentHeaderStyles}>
          <Avatar name={appointment.providerName} size='medium' />
          <div>
            <Text weight='semibold'>{appointment.providerName}</Text>
            <Text size='small' textAlign='left' color='subdued'>
              {appointment.providerPractice}
            </Text>
          </div>
        </div>

        <div css={appointmentInfoStyles}>
          <Icon
            css={css`
              margin-top: 4px;
            `}
            name={isTelemedicine ? 'video' : 'calendar'}
            size={16}
            color={isTelemedicine ? 'primary' : 'subdued'}
          />
          <div>
            <Text size='large' weight='bold'>
              {formattedDate}
            </Text>
            <Text size='small' color='subdued'>
              {appointment.startTime} - {appointment.endTime}
            </Text>
          </div>
        </div>

        {isTelemedicine && (
          <div
            css={css`
              margin-top: 16px;
              display: flex;
              gap: 8px;
            `}
          >
            <Button variant='primary' size='small' iconName='video'>
              Join Call
            </Button>
            <Button variant='secondary' size='small'>
              Reschedule
            </Button>
          </div>
        )}
      </MotionNakedButton>
      <AnimatePresence>
        {isModalOpen && (
          <AppointmentModal appointment={appointment} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        )}
      </AnimatePresence>
    </>
  );
};

interface AppointmentModalProps {
  appointment: Appointment;
  isOpen: boolean;
  onClose: () => void;
}

const sectionStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const rowStyles = css`
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }
`;

const actionButtonsStyles = css`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  justify-content: flex-end;
`;

const typeIconStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const modalOverlayStyles = css`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
`;

const modalStyles = css`
  width: 100%;
  max-width: 450px;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.large};
  overflow: hidden;
  margin: ${theme.spacing(1)};
  position: relative;
  padding: 24px;
`;

const overlayVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

export const AppointmentModal = ({ appointment, onClose }: AppointmentModalProps) => {
  const cancelAppointment = useAppointmentStore((state) => state.cancelAppointment);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);

  const handleCancel = () => {
    if (!isConfirmingCancel) {
      setIsConfirmingCancel(true);
      return;
    }

    cancelAppointment(appointment.id);
    setIsConfirmingCancel(false);
    onClose();
  };

  const appointmentDate = new Date(`${appointment.date} ${appointment.startTime}`);
  const formattedDate = dayjs(appointmentDate).format('ddd, MMMM D, YYYY ');
  const formattedStartTime = dayjs(appointmentDate).format('h:mm a');
  const formattedEndTime = dayjs(`${appointment.date} ${appointment.endTime}`).format('h:mm a');

  return (
    <motion.div variants={overlayVariants} css={modalOverlayStyles} initial='hidden' animate='visible' exit='exit'>
      <motion.div css={modalStyles}>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Heading level={2}>Appointment Details</Heading>
          <Button variant='secondary' iconName='x' onClick={onClose} />
        </div>
        <motion.div>
          <div css={sectionStyles}>
            <div css={typeIconStyles}>
              <Chip
                maxWidth='500px'
                leftElement={<Icon name={appointment.type === 'telehealth' ? 'video' : 'location'} size={20} />}
              >
                {appointment.type === 'telehealth' ? 'Telehealth Visit' : 'In-Person Visit'}
              </Chip>
            </div>
          </div>

          <div css={sectionStyles}>
            <div css={rowStyles}>
              <Text color='subdued'>Date & Time</Text>
              <div>
                <Text>{formattedDate}</Text>
                <Text color='subdued' size='medium'>
                  {formattedStartTime} - {formattedEndTime}
                </Text>
              </div>
            </div>

            <div css={rowStyles}>
              <Text color='subdued'>Provider</Text>
              <Text>
                {appointment.providerName}
                <br />
                <Text size='small' color='subdued'>
                  {appointment.providerPractice}
                </Text>
              </Text>
            </div>

            <div css={rowStyles}>
              <Text color='subdued'>Reason for Visit</Text>
              <Text>{appointment.reason}</Text>
            </div>
          </div>

          <div css={actionButtonsStyles}>
            <Button variant='primary'>Reschedule</Button>

            <Button variant='primary' destructive onClick={handleCancel}>
              {isConfirmingCancel ? 'Confirm Cancellation' : 'Cancel Appointment'}
            </Button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const emptyStateStyles = css`
  text-align: center;
  color: #6b7280;
  padding: 24px 0;
`;

export const AppointmentsView = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const appointments = useAppointmentStore((state) => state.getUpcomingAppointments());

  return (
    <Card title='Appointments' action=''>
      {appointments.length === 0 ? (
        <div css={emptyStateStyles}>No upcoming appointments</div>
      ) : (
        <>
          <div
            css={css`
              margin-bottom: 16px;
            `}
          >
            <AppointmentCard appointment={appointments[0]} />
          </div>
          {appointments.length > 1 && (
            <AnimatePresence initial={false}>
              {isExpanded && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  css={css`
                    overflow: hidden;
                  `}
                >
                  <div css={appointmentListStyles}>
                    {appointments.slice(1).map((appointment) => (
                      <AppointmentCard key={appointment.id} appointment={appointment} />
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          )}

          {appointments.length > 1 && (
            <div
              css={[
                viewMoreStyles,
                css`
                  text-align: center;
                  width: 100%;
                `,
                isExpanded &&
                  css`
                    margin-top: 16px;
                  `,
              ]}
            >
              <Button css={css``} onClick={() => setIsExpanded(!isExpanded)} variant='tertiary'>
                {isExpanded ? 'Show Less' : 'View More'}
                <Icon name={isExpanded ? 'caret-up' : 'caret-down'} />
              </Button>
            </div>
          )}
        </>
      )}
    </Card>
  );
};

const viewMoreStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(2)} 0 0;
  color: ${theme.colors.primary};
  cursor: pointer;
  border-top: 1px solid ${theme.colors.neutral10};

  &:hover {
    color: ${theme.colors.primary60};
  }
`;
