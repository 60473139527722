import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './root';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <Suspense fallback='loading'>
      <Root />
    </Suspense>
  </StrictMode>
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(import.meta.env.MODE === 'production' ? '/service-worker.js' : '/dev-sw.js?dev-sw');
}
