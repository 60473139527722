// import { css } from '@emotion/react';
// import { useNavigate } from '@tanstack/react-location';
// import { useMutation } from 'react-query';
// import { WeaveLogoNew } from '@frontend/assets';
// import { theme } from '@frontend/theme';
// import { TextField, Button, useFormField, FormRow, Text, Heading } from '@frontend/design-system';
// import { useIsAuthedShallowStore } from '../../../is-authed';
// import { usePersonStore } from '../../../stores/personStore';
// import type { Person } from '../../../person-types';
// import { useEffect } from 'react';

// const environmentVariables = {
//   baseApiUrl: 'https://api.weaveconnect.com',
// };

// const urls = {
//   getOtp: `${environmentVariables.baseApiUrl}/forms-digital/v1/prepopulation/otp`,
//   verifyOtp: `${environmentVariables.baseApiUrl}/forms-digital/v1`,
// };

// export const decodeParamToObject = (searchParamString: string, paramName = 'personPayload') => {
//   try {
//     const searchParams = new URLSearchParams(searchParamString);
//     const base64String = searchParams.get(paramName);

//     if (!base64String) {
//       return;
//     }

//     const padding = '='.repeat((4 - (base64String.length % 4)) % 4);

//     const standardBase64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

//     const jsonString = atob(standardBase64);

//     return JSON.parse(jsonString);
//   } catch (error) {
//     console.error('Error decoding parameter:', error);
//     return null;
//   }
// };

// const personData = decodeParamToObject(window.location.href) as Person | undefined;
// export default function SignIn() {
//   const { signIn } = useIsAuthedShallowStore('signIn');
//   const navigate = useNavigate();
//   const fieldProps = useFormField({
//     type: 'text',
//   });
//   const { setPerson } = usePersonStore('setPerson');
//   const queryParams = new URLSearchParams(window.location.search);
//   const companyId = queryParams.get('company_id');
//   const qwe = queryParams.get('qwe');
//   const hasFields = qwe && companyId;

//   const requestOtpMutation = useMutation({
//     mutationFn: async () => {
//       const response = await fetch(urls.getOtp, { method: 'POST', body: JSON.stringify({ id: qwe }) });
//       if (!response.ok) {
//         throw new Error('Failed to request OTP');
//       }
//       return await response.json();
//     },
//   });

//   function maskPhoneNumber(phone: string): string {
//     const cleanNumber = phone.replace(/\D/g, '').slice(-10);

//     return `(***) ***-**${cleanNumber.slice(-2)}`;
//   }

//   useEffect(() => {
//     if (qwe && requestOtpMutation.isIdle) {
//       requestOtpMutation.mutate();
//     }
//   }, []);

//   const verifyOtpMutation = useMutation({
//     mutationFn: async (otp: string) => {
//       const response = await fetch(
//         `${urls.verifyOtp}?includeOptions=true&secretCode=${otp}&qwe=${qwe}&company_id=${companyId}`
//       );
//       if (!response.ok) {
//         throw new Error('Error validating OTP.');
//       }
//       return await response.json();
//     },
//     onSuccess: () => {
//       if (personData) {
//         // setPerson(personData as Person);
//       }
//       signIn();
//       navigate({ to: '/home' });
//     },
//   });

//   return (
//     <div
//       css={{
//         alignItems: 'center',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         justifyContent: 'center',
//         width: '100%',
//       }}
//     >
//       <div
//         css={css`
//           margin-bottom: ${theme.spacing(4)};
//           max-width: 280px;

//           svg {
//             width: 200px;
//             height: auto;
//           }
//         `}
//       >
//         <Heading
//           css={css`
//             margin-top: ${theme.spacing(3)};
//             margin-bottom: ${theme.spacing(1)};
//           `}
//           level={2}
//         >
//           Welcome to Weave Patient Portal
//         </Heading>
//         {personData?.MobilePhone && (
//           <Text>Enter the one time passcode sent to {maskPhoneNumber(personData?.MobilePhone)}</Text>
//         )}

//         <div
//           css={css`
//             margin: ${theme.spacing(3, 0)};
//           `}
//         >
//           <TextField
//             placeholder='******'
//             name='phone-number'
//             label=''
//             {...fieldProps}
//             css={css`
//               height: 48px;
//               width: 100%;
//               max-width: 518px;

//               border-radius: ${theme.borderRadius.medium};
//               border: 1px solid ${theme.colors.neutral20};
//               :focus {
//                 border: 1px solid ${theme.colors.primary20};
//               }
//               input::placeholder {
//                 color: ${theme.colors.neutral90} !important;
//                 transform: translateY(3px);
//               }
//             `}
//           />
//           {verifyOtpMutation.isError || requestOtpMutation.isError ? (
//             <Text
//               color='error'
//               css={css`
//                 margin-top: 4px;
//               `}
//             >
//               Encountered an error
//             </Text>
//           ) : null}
//         </div>
//         {/* <Button
//             onClick={() => {
//               verifyOtpMutation.reset();
//               requestOtpMutation.mutate();
//             }}
//             disabled={!hasFields || requestOtpMutation.isSuccess}
//           >
//             Request OTP
//           </Button> */}
//         {/* <Button
//             loading={verifyOtpMutation.isLoading}
//             variant='secondary'
//             disabled={fieldProps.value.length !== 6}
//             onClick={() => {
//               verifyOtpMutation.mutate(fieldProps.value);
//               requestOtpMutation.reset();
//             }}
//           >
//             Validate code
//           </Button> */}
//         <Button
//           css={css`
//             width: 100%;
//             height: 48px;
//             font-size: ${theme.font.size.h3};
//             border-radius: ${theme.borderRadius.medium};
//           `}
//           loading={verifyOtpMutation.isLoading}
//           disabled={fieldProps.value.length !== 6}
//           onClick={() => {
//             verifyOtpMutation.mutate(fieldProps.value);
//             requestOtpMutation.reset();
//           }}
//         >
//           Login
//         </Button>
//         <Button
//           css={css`
//             margin-top: ${theme.spacing(1)};
//           `}
//           variant='tertiary'
//         >
//           Resend code...
//         </Button>
//       </div>
//     </div>
//   );
// }

import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { motion } from 'framer-motion';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useIsAuthedShallowStore } from '../../../is-authed';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: ${theme.colors.white};
  overflow: hidden;
`;

const iconContainerStyle = css`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const textStyle = css`
  margin-top: ${theme.spacing(3)};
  color: ${theme.colors.neutral70};
  font-size: ${theme.fontSize(18)};
  font-weight: ${theme.font.weight.semibold};
`;

const progressBarStyle = css`
  margin-top: ${theme.spacing(4)};
  width: 240px;
  height: 4px;
  background-color: ${theme.colors.neutral10};
  border-radius: 2px;
  overflow: hidden;
`;

const loadingStates = [
  { icon: 'wifi', label: 'Establishing connection...' },
  { icon: 'lock', label: 'Securing channel...' },
  { icon: 'user', label: 'Loading your profile...' },
  { icon: 'check', label: 'All set!' },
] satisfies { icon: IconName; label: string }[];

const SignIn = () => {
  const [currentStateIndex, setCurrentStateIndex] = useState(0);
  const navigate = useNavigate();
  const { signIn } = useIsAuthedShallowStore('signIn');

  useEffect(() => {
    const sequence = [500, 1000, 800, 700];
    let totalDelay = 0;

    loadingStates.forEach((_, index) => {
      if (index < loadingStates.length - 1) {
        setTimeout(() => setCurrentStateIndex(index + 1), totalDelay);
        totalDelay += sequence[index];
      }
    });

    setTimeout(() => {
      signIn();
      navigate({ to: '/home' });
    }, totalDelay + 500);

    return () => {};
  }, []);

  const currentIcon = loadingStates[currentStateIndex].icon;
  const progress = (currentStateIndex + 1) / loadingStates.length;

  return (
    <div css={containerStyle}>
      <div css={iconContainerStyle}>
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: 'spring', duration: 0.8 }}
        >
          <Icon
            size={32}
            css={css`
              color: ${theme.colors.primary50};
            `}
            name={currentIcon}
          />
        </motion.div>
      </div>

      <motion.div
        css={textStyle}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        key={currentStateIndex}
        transition={{ duration: 0.3 }}
      >
        {loadingStates[currentStateIndex].label}
      </motion.div>

      <div css={progressBarStyle}>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${progress * 100}%` }}
          transition={{ duration: 0.3 }}
          style={{
            height: '100%',
            backgroundColor: theme.colors.primary50,
            borderRadius: '2px',
          }}
        />
      </div>
    </div>
  );
};

export default SignIn;
