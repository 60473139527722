import { css } from '@emotion/react';
import { Link, useNavigate } from '@tanstack/react-location';
import { PersonHelpers } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Avatar,
  NakedButton,
  NakedUl,
  Text,
  type TextProps,
  Tray,
  useModalControl,
  useVerticalSlideModal,
  VerticalSlideModal,
} from '@frontend/design-system';
import { useIsAuthedShallowStore } from '../is-authed';
import { usePersonStore } from '../stores/personStore';
import { ProviderMessaging } from './provider-messaging';

export const FirstWordBoldText = (props: Omit<TextProps, 'children'> & { children: string }) => {
  const { children: text, ...restProps } = props;
  const [firstWord, restWord] = (text ?? '').split(/\s(.*)/s);

  return (
    <Text
      as='h3'
      css={css`
        font-size: ${theme.font.size.h3};
      `}
      {...restProps}
    >
      <strong>{firstWord}</strong> {restWord}
    </Text>
  );
};

const HouseholdCard = ({ title, subtitle }: { title: string; subtitle: string }) => (
  <div
    css={css`
      border-radius: ${theme.borderRadius.medium};
      border: 1px solid ${theme.colors.neutral20};
      padding: ${theme.spacing(1)};
    `}
  >
    <Text weight='bold' textAlign='center'>
      {title}
    </Text>
    <Text textAlign='center'>{subtitle}</Text>
  </div>
);

const user = {
  username: 'adrian.foong@getweave.com',
  firstName: 'Nadya',
  lastName: 'Maryam',
  mobileNumber: '123-123-1234',
  gender: 'Male',
  type: 'weave',
  userID: '820db53f-74f5-4bf8-a3f9-05c196d6c8de',
  birthDate: '7/16/1989',
} as const;

export const PageHeader = () => {
  const { modalProps: verticalModalProps, triggerProps: verticalTriggerProps, setOpen } = useVerticalSlideModal();
  const { modalProps, triggerProps } = useModalControl();
  const navigate = useNavigate();
  const { signOut } = useIsAuthedShallowStore('signOut');
  const { person } = usePersonStore('person');

  return (
    <>
      <header
        css={{
          padding: theme.spacing(2),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors.neutral10}`,
        }}
      >
        <NakedButton
          css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}
          ref={triggerProps.ref}
          onClick={modalProps.show ? modalProps.onClose : triggerProps.onClick}
        >
          <Avatar
            src={person?.profilePicture}
            size='small'
            name={PersonHelpers.getFullName({
              FirstName: person?.FirstName ?? user.firstName,
              LastName: person?.FirstName ?? user.lastName,
            })}
          />
          <div>
            <FirstWordBoldText as={Text} size='large'>
              {person?.FirstName
                ? PersonHelpers.getFullName({ FirstName: person?.FirstName, LastName: person.LastName })
                : user.firstName}
            </FirstWordBoldText>
          </div>
          <Icon
            name='caret-down-small'
            css={{ transform: modalProps.show ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 250ms' }}
          />
        </NakedButton>
        <NakedButton {...verticalTriggerProps}>
          <Icon name='inbox' />
        </NakedButton>
        <Tray
          animateOrigin='top'
          mountTarget='#main-content'
          {...modalProps}
          css={{ width: '100%', height: 'min-content', maxWidth: 'unset', padding: theme.spacing(2) }}
        >
          <NakedUl
            css={{
              borderBottom: `1px solid ${theme.colors.neutral20}`,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: theme.spacing(1),
              paddingBottom: theme.spacing(2),
            }}
          >
            <li>
              <HouseholdCard title='Jasper Seawright' subtitle='16 years old' />
            </li>
            <li>
              <HouseholdCard title='Beatrice Seawright' subtitle='12 years old' />
            </li>
            <li>
              <HouseholdCard title='Pickles' subtitle='Cat' />
            </li>
          </NakedUl>
          <NakedUl
            css={css`
              a,
              button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: ${theme.spacing(2)};
                width: 100%;
              }
              a {
                color: unset;
                text-decoration: unset;
              }
            `}
          >
            <li>
              <Link to='/profile' onClick={modalProps.onClose}>
                <Text>Manage Account</Text>
                <Icon name='caret-right-small' />
              </Link>
            </li>
            <li>
              <Link to='/help-center' onClick={modalProps.onClose}>
                <Text>Help Center</Text>
                <Icon name='caret-right-small' />
              </Link>
            </li>
            <li>
              <Link to='/health-articles' onClick={modalProps.onClose}>
                <Text>Health Articles</Text>
                <Icon name='caret-right-small' />
              </Link>
            </li>
            <li>
              <NakedButton
                onClick={() => {
                  signOut();
                  navigate({ to: '/sign-in' });
                }}
              >
                <Text>Sign Out</Text>
                <Icon name='log-out-small' />
              </NakedButton>
            </li>
          </NakedUl>
        </Tray>
      </header>
      <VerticalSlideModal {...verticalModalProps}>
        <ProviderMessaging onClose={() => setOpen(false)} />
      </VerticalSlideModal>
    </>
  );
};
