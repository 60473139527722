import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Button, Avatar, Chip } from '@frontend/design-system';
import { SecureMessaging } from './secure-messaging';
import { VideoCall } from './telehealth';
import { UnifiedAppointmentModal } from './telehealth-appointment';

interface Provider {
  id: string;
  name: string;
  specialty: string;
  title: string;
  avatarUrl?: string;
  isOnline: boolean;
  nextAvailable: string;
  rating: number;
  totalReviews: number;
  languages: string[];
  education: string;
  isVerified: boolean;
  practice: string;
}

const providers: Provider[] = [
  {
    id: 'doctor1',
    name: 'Dr. Charles Mingus',
    specialty: 'Cardiology',
    practice: 'Sweet Smiles Dental',
    title: 'MD, FACC',
    isOnline: true,
    nextAvailable: 'Available now',
    rating: 4.9,
    totalReviews: 128,
    languages: ['English', 'Mandarin'],
    education: 'Stanford Medical School',
    isVerified: true,
  },
  {
    id: 'doctor2',
    name: 'Dr. Chuck Berry',
    specialty: 'Internal Medicine',
    practice: 'Serene Valley Pet Clinic',

    title: 'MD',
    isOnline: true,
    nextAvailable: 'Available now',
    rating: 4.8,
    totalReviews: 96,
    languages: ['English', 'Spanish'],
    education: 'UCLA Medical School',
    isVerified: true,
  },
  {
    id: 'doctor3',
    name: 'Dr. Dolly Parton',
    specialty: 'Family Medicine',
    title: 'MD',
    isOnline: false,
    nextAvailable: 'Available in 2 hours',
    practice: 'Happy Eyes Optometry',

    rating: 4.7,
    totalReviews: 156,
    languages: ['English'],
    education: 'Johns Hopkins School of Medicine',
    isVerified: true,
  },
];

const headerStyles = css`
  padding: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.colors.neutral20};
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

const containerStylesProviders = css`
  margin: 0 auto;
  width: 100%;
`;

const searchContainerStyles = css`
  padding: 12px 24px;
  border-bottom: 1px solid ${theme.colors.neutral10};
`;

const searchInputStyles = css`
  width: 100%;
  padding: 12px;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: 8px;
  font-size: 16px;
  background: ${theme.colors.white};

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary50};
    box-shadow: 0 0 0 2px ${theme.colors.primary20};
  }
`;

const providersListStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

const providerCardStyles = css`
  padding: ${theme.spacing(2.5)};
  border-radius: ${theme.borderRadius.large};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.neutral20};
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.light};
    border-color: ${theme.colors.primary50};
  }
`;

const providerHeaderStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${theme.spacing(2)};
`;

const providerInfoStyles = css`
  display: flex;
  gap: ${theme.spacing(2)};
`;

const ratingStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
`;

const buttonGroupStyles = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

interface ProvidersListProps {
  onSelectProvider: (provider: Provider) => void;
  onClose: () => void;
  selectedProvider: Provider | null;
  setSecureMessaging: (isActive: boolean) => void;
}

const ProvidersList: React.FC<ProvidersListProps> = ({
  onSelectProvider,
  selectedProvider,
  onClose,
  setSecureMessaging,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showTelehealth, setShowTelehealth] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);

  const filteredProviders = providers.filter(
    (provider) =>
      provider.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      provider.specialty.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div css={containerStylesProviders}>
      <div css={[headerStyles]}>
        <div
          css={css`
            display: flex;
            width: 100%;
            align-items: center;
            gap: ${theme.spacing(1)};
            padding: ${theme.spacing(0.5, 1, 0)};
          `}
        >
          <Button
            onClick={() => onClose()}
            css={css`
              margin-right: -4px;
              margin-left: -12px;
            `}
            iconName='caret-left'
            variant='secondary'
          />
          <Icon color='success' name='shield' />{' '}
          <Text weight='bold' size='large'>
            Secure Messaging
          </Text>
        </div>
      </div>
      <div css={searchContainerStyles}>
        <input
          css={searchInputStyles}
          type='text'
          placeholder='Search by provider name or specialty...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div css={providersListStyles}>
        {filteredProviders.map((provider) => (
          <div key={provider.id} css={providerCardStyles}>
            <div css={providerHeaderStyles}>
              <div css={providerInfoStyles}>
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <Avatar name={provider.name} size='large' />
                  {provider.isOnline && (
                    <div
                      css={css`
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 12px;
                        height: 12px;
                        background: ${theme.colors.success};
                        border-radius: 50%;
                        border: 2px solid ${theme.colors.white};
                      `}
                    />
                  )}
                </div>
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: ${theme.spacing(1)};
                    `}
                  >
                    <Text weight='semibold'>{provider.name}</Text>
                    {provider.isVerified && <Icon name='shield' size={14} color='success' />}
                  </div>
                  <Text size='small' color='subdued'>
                    {provider.specialty} • {provider.title}
                  </Text>
                  <div
                    css={css`
                      display: flex;
                      gap: ${theme.spacing(1.5)};
                      margin-top: ${theme.spacing(0.5)};
                    `}
                  >
                    <div css={ratingStyles}>
                      <Icon name='star' size={14} color='warn' />
                      <Text size='small'>{provider.rating}</Text>
                    </div>
                    <Text size='small' color='subdued'>
                      {provider.languages.join(', ')}
                    </Text>
                  </div>
                </div>
              </div>
              <Chip
                maxWidth='200'
                leftElement={
                  <Icon
                    name={provider.isOnline ? 'credit-card' : 'clock'}
                    size={12}
                    color={provider.isOnline ? 'success' : 'subdued'}
                  />
                }
                variant={provider.isOnline ? 'success' : 'neutral'}
              >
                {provider.nextAvailable}
              </Chip>
            </div>

            <div css={buttonGroupStyles}>
              <Button variant='primary' size='small' iconName='video' onClick={() => setShowTelehealth(true)}>
                Video Visit
              </Button>
              <Button
                variant='secondary'
                size='small'
                iconName='message'
                onClick={() => {
                  setSecureMessaging(true);
                  onSelectProvider(provider);
                }}
              >
                Message
              </Button>
              <Button
                variant='secondary'
                size='small'
                iconName='calendar'
                onClick={() => {
                  onSelectProvider(provider);
                  setShowAppointmentModal(true);
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        ))}
      </div>
      {showTelehealth && <VideoCall onEndCall={() => setShowTelehealth(false)} />}
      {showAppointmentModal && selectedProvider && (
        <UnifiedAppointmentModal
          provider={{
            name: selectedProvider.name,
            practice: selectedProvider.practice,
          }}
          onClose={() => setShowAppointmentModal(false)}
          onBook={() => {
            setShowAppointmentModal(false);
          }}
        />
      )}
    </div>
  );
};

export const ProviderMessaging = ({ onClose }: { onClose: () => void }) => {
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [secureMessaging, setSecureMessaging] = useState(false);

  if (selectedProvider && secureMessaging) {
    return (
      <SecureMessaging
        onBack={() => {
          setSelectedProvider(null);
          setSecureMessaging(false);
        }}
      />
    );
  }

  return (
    <ProvidersList
      setSecureMessaging={setSecureMessaging}
      onClose={onClose}
      selectedProvider={selectedProvider}
      onSelectProvider={setSelectedProvider}
    />
  );
};
