import { ReactLocation, Router } from '@tanstack/react-location';
import { App } from '../app';
import { useIsAuthedShallowStore } from '../is-authed';
import SignIn from '../pages/public/sign-in';
import { getRoutes } from '../routes';

interface IMain {
  location: ReactLocation;
}

export const Main: React.FC<React.PropsWithChildren<IMain>> = ({ location }) => {
  const routes = getRoutes();
  const { isAuthed } = useIsAuthedShallowStore('isAuthed');

  return (
    <Router routes={routes} location={location}>
      {isAuthed ? <App /> : <SignIn />}
    </Router>
  );
};
